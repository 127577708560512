/* eslint-disable max-lines */
// FIXME: needs to go
import useLocations from '@/compositions/locations';
import { defineComponent, ref } from 'vue';
import Datepicker from 'vue3-datepicker';
import { nl } from 'date-fns/locale';
import { dateToTimeOfDay, DAY_MILLISECONDS, midnightOfDate } from '@/classes/timestamps';
import useVuelidate from '@vuelidate/core';
import { required, minValue } from '@vuelidate/validators';
import slotsAvailable from '@/validators/slotsAvailable';
import useAuth from '@/compositions/auth';
import CustomerSearchField from '@/components/Customers/CustomerSearchField';
import TimePicker from '@/components/Reservation/TimePicker';
import { cloneDeep } from 'lodash';
import Calendar from '@/components/Calendar';
export default defineComponent({
    name: 'ReservationForm',
    components: {
        Datepicker,
        CustomerSearchField,
        TimePicker,
        Calendar
    },
    props: {
        reservation: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: {
        isValid(_valid) { return true; }
    },
    setup(props) {
        const auth = useAuth();
        const now = auth.isSuperUser ? 0 : (midnightOfDate(new Date())).getTime();
        const afterToday = minValue(now);
        const earlyTime = ref(0);
        const afterFrom = minValue(earlyTime);
        const rules = {
            licenseplate: { required },
            __fromTimestamp: { afterToday },
            __toTimestamp: { afterFrom },
            slotsAvailable
        };
        return { validate: useVuelidate(rules, props.reservation), ...useLocations(), earlyTime, ...auth };
    },
    data: () => ({
        locale: nl,
        timeFormat: 'HH:mm',
        oldReservation: null
    }),
    computed: {
        registrationComplete() {
            return this.$store.direct.getters.auth.registrationComplete;
        },
        fromDate: {
            set(date) {
                const timeOfDay = this.reservation.__fromTimestamp - midnightOfDate(this.reservation.fromDate).getTime();
                this.reservation.__fromTimestamp = date.getTime() + timeOfDay;
            },
            get() {
                return this.reservation.fromDate;
            }
        },
        toDate: {
            set(date) {
                this.reservation.__toTimestamp = date.getTime() + 10 * 3600 * 1000;
            },
            get() {
                return this.reservation.toDate;
            }
        },
        arrivalTime: {
            get() {
                return dateToTimeOfDay(this.fromDate);
            },
            set(time) {
                var _a, _b;
                if (time.includes('mm') || time.includes('HH'))
                    return;
                const units = time.split(':');
                const hours = Number.parseInt((_a = units[0]) !== null && _a !== void 0 ? _a : '0');
                const minutes = Number.parseInt((_b = units[1]) !== null && _b !== void 0 ? _b : '0');
                const timeSinceMidnight = (hours - 2) * 3600 * 1000 + minutes * 60 * 1000;
                const fromDate = new Date(midnightOfDate(this.reservation.fromDate).getTime() + timeSinceMidnight);
                this.reservation.__fromTimestamp = fromDate.getTime();
            }
        },
        openingHours() {
            var _a;
            const openingHours = (_a = this.currentLocation) === null || _a === void 0 ? void 0 : _a.openingHours;
            if (openingHours === undefined)
                return 'Geen openingsuren beschikbaar';
            return `Openingsuren tussen ${openingHours.fromHour}u en ${openingHours.toHour}u`;
        },
        hourRange() {
            var _a;
            const openingHours = (_a = this.currentLocation) === null || _a === void 0 ? void 0 : _a.openingHours;
            if (openingHours === undefined)
                return [[13, 19]];
            return [[openingHours.fromHour, openingHours.toHour]];
        }
    },
    watch: {
        reservation: {
            handler(newVal) {
                var _a, _b;
                this.validate.$touch();
                this.validate.__toTimestamp.$reset();
                if (!this.isSuperUser) {
                    this.earlyTime = midnightOfDate(new Date(this.reservation.__fromTimestamp)).getTime();
                }
                const timesChanged = newVal.__fromTimestamp !== ((_a = this.oldReservation) === null || _a === void 0 ? void 0 : _a.__fromTimestamp) ||
                    newVal.__toTimestamp !== ((_b = this.oldReservation) === null || _b === void 0 ? void 0 : _b.__toTimestamp);
                if (timesChanged && this.oldReservation !== null) {
                    const diff = newVal.__toTimestamp - newVal.__fromTimestamp;
                    if (Math.abs(diff) < 10 * DAY_MILLISECONDS) {
                        const minFromTS = Math.min(newVal.__fromTimestamp - 31 * DAY_MILLISECONDS, this.$store.direct.state.daysummaries.filter.fromDate.getTime());
                        const maxToDateTS = Math.max(newVal.__toTimestamp + 31 * DAY_MILLISECONDS, this.$store.direct.state.daysummaries.filter.toDate.getTime());
                        this.$store.direct.dispatch.daysummaries.applyFilter({
                            fromDate: new Date(minFromTS),
                            toDate: new Date(maxToDateTS)
                        });
                    }
                }
                this.oldReservation = cloneDeep(newVal);
            },
            deep: true,
            immediate: true
        },
        'validate.$error'(error) {
            this.$emit('isValid', !error);
        }
    }
});
