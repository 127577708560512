/* eslint-disable max-lines */
import ReservationForm from '@/components/Reservation/ReservationForm';
import CalculateReservation from '@/components/Reservation/CalculateReservation';
import EditProfileForm from '@/components/EditProfile';
import TakeAwayForm from '@/components/TakeAway/TakeAwayForm';
import useAuth from '@/compositions/auth';
import useLocations from '@/compositions/locations';
import { useToast } from 'vue-toastification';
import { defineComponent } from 'vue';
import { Reservation } from '@/types/reservations';
import { DAY_MILLISECONDS, midnightOfDate, countDaysBetween } from '@/classes/timestamps';
import { TakeAway } from '@/types/takeaway';
import { cloneDeep } from 'lodash';
import useCustomers from '@/compositions/customers';
export default defineComponent({
    name: 'MakeReservationForm',
    components: {
        ReservationForm,
        TakeAwayForm,
        CalculateReservation,
        EditProfileForm
    },
    setup() {
        return { ...useAuth(), ...useLocations(), ...useCustomers(), toast: useToast() };
    },
    data: () => ({
        showModal: false,
        isValid: false,
        takeawayValid: [],
        reservation: null,
        takeaways: [],
        processing: false,
        visibleSlide: 0,
        userProfile: null,
        customerProfile: null,
        editProfile: false,
        key: 1
    }),
    computed: {
        valid() {
            if (!this.isValid)
                return false;
            return (!this.takeawayValid.some(valid => !valid) && this.validateTakeaway);
        },
        image() {
            return require('@/assets/newreservation.svg');
        },
        validateTakeaway() {
            var _a, _b, _c, _d;
            if (((_a = this.reservation) === null || _a === void 0 ? void 0 : _a.fromDate) === undefined || ((_b = this.reservation) === null || _b === void 0 ? void 0 : _b.toDate) === undefined)
                return true;
            else {
                const daysBetween = countDaysBetween((_c = this.reservation) === null || _c === void 0 ? void 0 : _c.fromDate, (_d = this.reservation) === null || _d === void 0 ? void 0 : _d.toDate);
                const factor = (this.takeaways.length / daysBetween);
                return factor >= 0.66;
            }
        },
        minimalRequiredTakeaway() {
            var _a, _b, _c, _d;
            if (((_a = this.reservation) === null || _a === void 0 ? void 0 : _a.fromDate) === undefined || ((_b = this.reservation) === null || _b === void 0 ? void 0 : _b.toDate) === undefined)
                return 0;
            else {
                const daysBetween = countDaysBetween((_c = this.reservation) === null || _c === void 0 ? void 0 : _c.fromDate, (_d = this.reservation) === null || _d === void 0 ? void 0 : _d.toDate);
                return Math.ceil(0.66 * daysBetween);
            }
        }
    },
    watch: {
        uid: {
            handler() {
                this.createDefaultReservation();
            },
            immediate: true
        },
        currentLocation: {
            handler() {
                this.createDefaultReservation();
            },
            immediate: true
        },
        userinfo: {
            handler(newVal) {
                this.userProfile = cloneDeep(newVal);
            },
            immediate: true
        },
        reservation: {
            handler(newVal) {
                if (newVal === null)
                    return;
                if (this.isSuperUser) {
                    const cust = this.customers[newVal.customerid];
                    this.customerProfile = cust !== undefined ? cust : null;
                }
                else {
                    this.customerProfile = this.userProfile;
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        profileUpdated() {
            this.editProfile = false;
            if (this.userProfile === null)
                return;
            this.$store.direct.dispatch.auth.updateUserInfo(this.userProfile);
        },
        createDefaultReservation() {
            if (this.uid !== undefined && this.currentLocation !== undefined && this.reservation === null) {
                const fromDate = new Date(midnightOfDate(new Date()).getTime() + 13 * 3600 * 1000);
                const toDate = new Date(fromDate.getTime() + DAY_MILLISECONDS);
                this.reservation = new Reservation(this.uid, fromDate, toDate, 2, this.currentLocationId);
                this.takeaways = [];
                this.takeawayValid = [];
            }
        },
        async makeReservation() {
            if (this.reservation === null)
                return;
            if (!this.valid)
                return;
            this.processing = true;
            try {
                const reservationId = await this.$store.direct.dispatch.reservations.makeReservation(this.reservation);
                const promises = [];
                this.takeaways.forEach(takeaway => {
                    takeaway.reservationId = reservationId;
                    if (this.reservation === null)
                        throw new Error('reservation is null');
                    takeaway.customerId = this.reservation.customerid;
                    promises.push(this.$store.direct.dispatch.takeaway.add(takeaway));
                });
                await Promise.all(promises);
                if (this.customerProfile !== null)
                    await this.$store.direct.dispatch.customers.update({ id: this.reservation.customerid, update: this.customerProfile });
                this.profileUpdated();
                this.nextSlide();
                this.toast.success('Uw reservatie aanvraag is succesvol ontvangen');
            }
            catch (error) {
                this.toast.error(`Er is een fout opgetreden tijdens de aanvraag van de reservatie ${error}`);
            }
            this.showModal = false;
            this.processing = false;
        },
        updateValidity(valid) {
            this.isValid = valid;
        },
        updateValidityTakeAway(index, valid) {
            this.takeawayValid[index] = valid;
        },
        removeTakeAway(index) {
            this.takeaways.splice(index, 1);
            this.takeawayValid.splice(index, 1);
        },
        addTakeAway(takeAwayDate = undefined) {
            var _a;
            let date = (_a = this.reservation) === null || _a === void 0 ? void 0 : _a.fromDate;
            if (takeAwayDate !== undefined)
                date = takeAwayDate;
            if (this.uid !== undefined && this.currentLocation !== undefined && date !== undefined) {
                const fromDate = new Date(midnightOfDate(date).getTime() + 12 * 3600 * 1000);
                this.takeaways.push(new TakeAway('unknown', this.uid, fromDate, this.currentLocationId));
                this.takeawayValid.push(false);
            }
        },
        clearTakeAway() {
            this.takeaways = [];
            this.takeawayValid = [];
            this.key++;
        },
        goToTakeAwaySlide() {
            this.nextSlide();
        },
        nextSlide() {
            var _a;
            this.visibleSlide += 1;
            (_a = document.querySelector('.reservation-wrapper .top')) === null || _a === void 0 ? void 0 : _a.scrollIntoView(true);
        },
        previousSlide() {
            this.visibleSlide -= 1;
        }
    }
});
