import { defineComponent } from 'vue';
import useReservations from '@/compositions/reservations';
import useTakeAway from '@/compositions/takeaway';
import { countDaysBetween } from '@/classes/timestamps';
export default defineComponent({
    name: 'CalculateReservation',
    props: {
        reservation: {
            type: Object,
            required: true
        },
        takeawaysNew: {
            type: Array,
            required: false,
            default: () => []
        },
        isNew: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup() {
        return {
            ...useReservations(),
            ...useTakeAway()
        };
    },
    computed: {
        camperNights() {
            var _a, _b;
            return countDaysBetween((_a = this.reservation) === null || _a === void 0 ? void 0 : _a.fromDate, (_b = this.reservation) === null || _b === void 0 ? void 0 : _b.toDate);
        },
        camperExtraPersons() {
            var _a;
            const numPers = (_a = this.reservation) === null || _a === void 0 ? void 0 : _a.numberPersons;
            if (numPers === undefined)
                return 0;
            else
                return ((numPers > 2) ? numPers - 2 : 0);
        },
        camperPrice() {
            return this.camperNights * 15 + this.camperExtraPersons * 0;
        },
        takeAwayPrice() {
            let total = 0;
            // If TakeAway prop passed else use passed prop
            if (this.isNew) {
                this.takeawaysNew.forEach(takeaway => {
                    total += takeaway.totalPrice();
                });
            }
            else {
                Object.values(this.takeaways).forEach(takeaway => {
                    total += takeaway.totalPrice();
                });
            }
            return total;
        }
    }
});
