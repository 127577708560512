import { defineComponent } from 'vue';
export default defineComponent({
    name: 'TimePicker',
    components: {},
    props: {
        minHours: {
            type: Number,
            default: 13,
            required: false
        },
        maxHours: {
            type: Number,
            default: 20,
            required: false
        },
        time: {
            type: String,
            required: true
        }
    },
    emits: ['update:time'],
    setup() {
        return {};
    },
    data: () => ({
        selectedHour: 13,
        selectedMinute: 0
    }),
    computed: {
        getHours() {
            const hours = [];
            for (let i = this.minHours; i <= this.maxHours; i++)
                hours.push(i);
            return hours;
        },
        getMinutes() {
            return [0, 15, 30, 45];
        }
    },
    mounted() {
        this.selectedHour = Number(this.time.slice(0, 2));
        this.selectedMinute = Number(this.time.slice(3, 5));
    },
    methods: {
        onTimeChange() {
            const time = this.selectedHour.toString().padStart(2, '0')
                + ':' + this.selectedMinute.toString().padStart(2, '0');
            this.$emit('update:time', time);
        }
    }
});
